<template>
    <div class="shop_main">
        <div class="shop_search_menu">
            <a href="javascript:void(0)" @click="$router.replace('/index')">首页</a>
            <i class="el-icon-arrow-right"></i>
            <span class="on">增值服务</span>
        </div>

        <div class="shopServices">
            <div class="item">
                <div class="block"></div>
                <div class="img" @click="$router.push('/shop/services/finance')"><img :src="'./img/services01.png'" /></div>
            </div>
            <div class="item">
                <div class="block"></div>
                <div class="img" @click="$router.push('/shop/services/taxation')"><img :src="'./img/services02.png'" /></div>
            </div>
            <div class="item">
                <div class="block"></div>
                <div class="img" @click="$router.push('/shop/services/detect')"><img :src="'./img/services03.png'" /></div>
            </div>
            <div class="item">
                <div class="block"></div>
                <div class="img" @click="$router.push('/shop/services/safe')"><img :src="'./img/services04.png'" /></div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "shopServices",
    data () {
      return {}
    },
    created() {
      this.$store.commit('changeScrollTop');
    },
    methods: {},
    components: {}
  }
</script>
